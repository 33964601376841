// assets
import { IconWallet, IconFileInvoice } from '@tabler/icons';

// constant
const icons = {
    IconFileInvoice,
    IconWallet
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Menu',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'requests',
            title: 'Requests',
            type: 'collapse',
            icon: icons.IconFileInvoice,

            // children: [
            //     {
            //         id: 'login3',
            //         title: 'Login',
            //         type: 'item',
            //         url: '/login'
            //     },
            //     {
            //         id: 'register3',
            //         title: 'Register',
            //         type: 'item',
            //         url: '/register'
            //     }
            // ]
            children: [
                // {
                //     id: 'requestsCenter',
                //     title: 'View Requests',
                //     type: 'item',
                //     url: '/login'
                // },
                {
                    id: 'createRequests',
                    title: 'Create Requests',
                    type: 'item',
                    url: '/createRequest'
                }
            ]
        },
        {
            id: 'refunds',
            title: 'Refunds',
            type: 'collapse',
            icon: icons.IconWallet
        }
    ]
};

export default pages;
