import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import reducer from './reducer';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['user']
};

const persistedReducer = persistReducer(persistConfig, reducer);

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
