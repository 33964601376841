import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const CreateWallet = Loadable(lazy(() => import('views/pages/authentication/authentication3/CreateWallet')));
const CreateRequests = Loadable(lazy(() => import('views/pages/requests/CreateRequests')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = (isAuthenticated) => [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
            },
            {
                path: '/dashboard',
                element: isAuthenticated ? <DashboardDefault /> : <Navigate to="/login" />
            },
            {
                path: '/login',
                element: <AuthLogin3 />
            },
            {
                path: '/register',
                element: <AuthRegister3 />
            },
            {
                path: '/createWallet',
                element: isAuthenticated ? <CreateWallet /> : <Navigate to="/login" />
            },
            // {
            //     path: '/createRequest',
            //     element: isAuthenticated ? <CreateRequests /> : <Navigate to="/login" />
            // },
            {
                path: '/createRequest',
                element: <CreateRequests />
            },
            {
                path: '/utils/util-typography',
                element: <UtilsTypography />
            },
            {
                path: '/utils/util-color',
                element: <UtilsColor />
            },
            {
                path: '/utils/util-shadow',
                element: <UtilsShadow />
            },
            {
                path: '/icons/tabler-icons',
                element: <UtilsTablerIcons />
            },
            {
                path: '/icons/material-icons',
                element: <UtilsMaterialIcons />
            },
            {
                path: '/sample-page',
                element: <SamplePage />
            }
        ]
    }
];

export default MainRoutes;
