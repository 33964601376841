// action - state management
import * as actionTypes from './actions';

export const initialState = {
    userId: null,
    firstName: null,
    lastName: null,
    walletLoaded: false,
    walletName: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
    let userId;
    let isAuthenticated;
    let firstName;
    let lastName;
    switch (action.type) {
        case actionTypes.USER_LOGIN:
            userId = action.userId;
            firstName = action.firstName;
            lastName = action.lastName;
            isAuthenticated = action.isAuthenticated;
            return {
                ...state,
                userId,
                firstName,
                lastName,
                isAuthenticated
            };
        case actionTypes.USER_LOGOUT:
            return {
                ...state,
                userId: null,
                firstName: null,
                lastName: null,
                isAuthenticated: false,
                walletLoaded: false,
                walletName: null
            };
        case actionTypes.WALLET_LOADED:
            return {
                ...state,
                walletLoaded: action.walletLoaded,
                walletName: action.walletName
            };
        default:
            return state;
    }
};

export default userReducer;
