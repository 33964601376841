import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import MainRoutes from './routes/MainRoutes';
import config from 'config';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

const queryClient = new QueryClient();

// ==============================|| APP ||============================== //

const App = () => {
    const user = useSelector((state) => state.user);
    const mainRoutes = MainRoutes(user.isAuthenticated);
    const routing = useRoutes([...mainRoutes], config.basename);
    const customization = useSelector((state) => state.customization);

    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>{routing}</NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;
